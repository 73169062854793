function generateCards() {
    $.getJSON('../json/fleurs.json', function(data) {

        $.each(data.flowers, function(i, flower) {
            let template =
            `<a class="card" id="${flower.name.toLowerCase()}" href="${flower.name.toLowerCase()}.html">
                <div class="card-front"></div>
                <div class="card-reverse">
                    <div class="content">
                        <table>
                            <tr>
                                <td>Age: </td>
                                <td>${flower.age}</td>
                            </tr>
                            <tr>
                                <td>Height: </td>
                                <td>${flower.height}</td>
                            </tr>
                            <tr>
                                <td>Weight: </td>
                                <td>${flower.weight}</td>
                            </tr>
                            <tr>
                                <td>Measurements: </td>
                                <td>${flower.measurements}</td>
                            </tr>
                            <tr>
                                <td>Feet size: </td>
                                <td>${flower.feet}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <h2>${flower.name}</h2>
            </a>`;

            $('.portfolio .cards-holder').append(template);

            $(`#${flower.name.toLowerCase()} .card-front`).css({
                'background': `linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0), rgba(255,255,255,1)), url(../img/${flower.photo})`,
                'background-size': 'cover',
                'background-repeat': 'no-repeat',
                'background-position': `${flower.position}`,
                'height': '80%',
                'width': '100%'
            });
        })
    })
}

export { generateCards };
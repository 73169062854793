function handleServiceShowing() {
    let services = $('.servicecheckbox');

    console.log(services);

    let girlfriend = $('#girlfriend');
    let webcam = $('#webcam');
    let texting = $('#sexting');
    let videos = $('#videos');
    let photos = $('#photos');

    services.on('click', function() {
        services.each(checkIfWeShow);
    });

    function checkIfWeShow() {

        console.log('checkIfWeShow Launched');

        if (this.checked === true) {
            console.log('checked');
            $(`#${this.id}Box`).removeClass('hidden');
        } else {
            console.log('not-checked');
            $(`#${this.id}Box`).addClass('hidden');
        }
    }
}

function handleServiceDropdown() {
    let servicesHeader = $('.service-choice .header');

    console.log(servicesHeader);

    servicesHeader.click(handleDropdown);

    function handleDropdown(event) {

        console.log(this);

        event.preventDefault();
        

        console.log('hullo');

        console.log($(this).children('.fa-angle-down'));
        console.log($(this).children('.fa-angle-up'));
        console.log($(this).siblings('.service-content'));

        if ($(this).siblings('.service-content').hasClass('showthis')) {
            $(this).children('.fa-angle-down').addClass('hidden');
            $(this).children('.fa-angle-up').removeClass('hidden');
            $(this).siblings('.service-content').removeClass('showthis');
        } else {
            $(this).children('.fa-angle-down').removeClass('hidden');
            $(this).children('.fa-angle-up').addClass('hidden');
            $(this).siblings('.service-content').addClass('showthis');
        }
    }
}

export { handleServiceShowing, handleServiceDropdown };
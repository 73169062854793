function hideDisclaimer() {
    const btn = $('.disclaimer button');
    const disclaimer = $('.disclaimer');

    if (sessionStorage.getItem('has-seen-disclaimer') !== null) {
        disclaimer.addClass('hidden');
        $('body').removeClass('noscroll');
    }

    btn.click(() => {
        disclaimer.addClass('hidden');
        $('body').removeClass('noscroll');
        sessionStorage.setItem('has-seen-disclaimer', 'true');
    });
}

export { hideDisclaimer };
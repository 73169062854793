import '../css/styles.scss';

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import { hideDisclaimer } from './disclaimer';

hideDisclaimer();

import { generateCards } from './portfolio';

generateCards();

import {handleServiceShowing, handleServiceDropdown} from './contact';

handleServiceShowing();
handleServiceDropdown();